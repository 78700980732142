<template>
  <div>
    <v-dialog v-model="value" max-width="500px">

      <v-card>
        <div class="title_body" style="margin-bottom: 20px">
          <div class="bar_top"></div>
          <div class="text_title text-h6">Sangria (Retirada)</div>

          <span class="btn_close"><v-btn icon @click="$emit('input', false)"><v-icon> {{ icons.mdiWindowClose }}
              </v-icon></v-btn>
          </span>
        </div>

        <v-card-text>

          <p><b>Reforço é quando se coloca dinheiro na Gaveta do Caixa, por exemplo para inserir troco. O Valor entra na
              Gaveta do Caixa mas não entra no relatório "Fluxo de Caixa",
              pois não representa dinheiro gerado pelas atividades do salão (como , por exemplo, no caso de uma venda ou
              recebimento de dívida).</b></p>

          <v-row no-gutters>
            <v-col cols="3">
              <vuetify-money v-model="data.deposit" class="" outlined dense label="Valor da Conta" />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-textarea v-model="data.obs" label="Obseservação" outlined name="" id="" rows="1"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="storeDeposit()" color="primary" rounded dark class=" float-right  " :active="loading" :loading="loading">
                OK
              </v-btn>

              <v-btn @click="close()" color="secondary" rounded dark class=" float-right mr-2 ">
                FECHAR
              </v-btn>

            </v-col>
          </v-row>
        </v-card-text>

      </v-card>
    </v-dialog>



  </div>
</template>

<script>

import {
  mdiWindowClose,
} from '@mdi/js'
import { mapActions } from 'vuex';

export default {
  props: {
    value: { type: Boolean }
  },
  components: {

  },
  watch: {
    value(val) {
      this.dialog = val;
    }
  },
  data: () => ({
    loading:false,
    data:{
      deposit:0,
      obs:''
    },

    icons: {
      mdiWindowClose,
    },

  }),
  methods: {
    ...mapActions('Cash',['deposit']),
    storeDeposit(){
      this.loading = true
      this.deposit(this.data).then((response)=>{
        this.$toast.success("Deposito realizado com sucesso")
        this.close()
      }).catch()
      .finally(()=>{
        this.loading = false
      })
    },
    close(){
      this.$emit('input', false)
    }

  }
}
</script>
