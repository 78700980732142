<template>
<v-container>
    <v-card elevation="2">
        <v-toolbar elevation="0">
            <v-toolbar-title>Caixa</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="search" :append-icon="icons.mdiMagnify" single-line label="Localizar" class="" outlined filled rounded dense hide-details @click:append="" @change=""></v-text-field>
            <!-- <InputDate css="mt-7 ml-2 col-2" ></InputDate> -->
            <v-spacer></v-spacer>
            <v-btn @click="storeCloseCash()" color="error" rounded dark class=" mr-2 ">
                <v-icon small> </v-icon>Fechar Caixa
            </v-btn>
            <v-menu>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" rounded dark class="" v-bind="attrs" v-on="on">
                        <v-icon small>{{ icons.mdiPlus }} </v-icon>Lançar Movimentação
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="showWithdrawal()">
                        <v-list-item-title>
                            <v-icon>{{ icons.mdiFileExcel }}</v-icon>Sangria (Retirada)
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="showDeposit()">
                        <v-list-item-title>
                            <v-icon>{{ icons.mdiFilePdf }}</v-icon>Reforço (Depósito)
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title>
                            <v-icon>{{ icons.mdiFilePdf }}</v-icon>Emitir Vale (ADIANTAMENTO)
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title>
                            <v-icon>{{ icons.mdiFilePdf }}</v-icon>Receber Dívidas
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-title>
                            <v-icon>{{ icons.mdiFilePdf }}</v-icon>Repassar Gorjetas
                        </v-list-item-title>
                    </v-list-item>

                </v-list>
            </v-menu>
        </v-toolbar>

        <v-card>
            <v-card-text>
                <v-row no-gutters>
                    <v-col>
                        <h4>Saldo Inicial: <span class=""> R$ {{ formatMoney(extract.initial_cash||0)}} </span></h4>
                    </v-col>
                    <v-col>
                        <h4>Entradas: <span class="signal_positive"> R$ {{ formatMoney(extract.input||0)}} </span></h4>
                    </v-col>
                    <v-col>
                        <h4>Saidas: <span class="signal_negative"> R$ {{ formatMoney(extract.output||0)}} </span></h4>
                    </v-col>
                    <v-col>
                        <h4>Saldo: {{ formatMoney(extract.balance||0)}}</h4>
                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog width="600" v-model="dialogCloseCash">
          <v-card>
            <BarTitleDialog title="Fechar Caixa" @close="dialogCloseCash=false"/>
              <v-card-text>
                <h1 class="text-center">Deseja realmente fechar o caixa ?</h1>
                <h4 class="text-center"><p>Antes, é importante conferir se os valores estão corretos.</p></h4>
                <v-row>
                  <v-col>
                    <v-textarea v-model="obsCloseCash" outlined label="Observação" rows="2"></v-textarea>
                  </v-col>
                </v-row>

              </v-card-text>

              <v-card-actions>
                <v-row>
                  <v-col class="text-right">
                    <v-btn x-large color="error" class="ml-2" @click="" >Não</v-btn>
                    <v-btn x-large color="primary" class="ml-2" :loading='loadingCloseCash' :disabled="loadingCloseCash" @click="confirmCloseCash()" >Sim</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
          </v-card>
        </v-dialog>

        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>DATA</th>
                        <th>CATEGORIA</th>
                        <th>DESCRIÇÃO</th>
                        <th>CLIENTE/FORNECEDOR</th>
                        <!-- <th>Conta</th> -->
                        <th>VALOR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,key) in extract.data" :key="key">
                        <td>{{ formatDateTime(item.date) }}</td>
                        <td>{{ item.category }}</td>
                        <td>{{ item.description  }}</td>
                        <td>{{ item.customer_supplier }}</td>
                        <!-- <td>{{ }}</td> -->
                        <td  class="text-right" ><b><i   :class="{signal_negative:(item.signal=='-'),signal_positive:(item.signal=='+')}">{{ formatMoney(item.value) }}</i></b></td>

                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>

    <!-- <ModalWithdrawal v-model="showModalWithdrawal" /> -->

    <ModalDeposit v-model="showModalDeposit" />

</v-container>
</template>

<script>

import {
    mdiMagnify,
    mdiPlus
} from '@mdi/js'

// import ModalWithdrawal from './_components/ModalWithdrawal.vue'
import ModalDeposit from './_components/ModalDeposit.vue'

import {
    mapActions,
    mapMutations
} from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
export default {
    components: {
        mdiPlus,
        // ModalWithdrawal,
        ModalDeposit,
        mdiMagnify,
        BarTitleDialog
    },
    data: () => ({
        obsCloseCash:'',
        loadingCloseCash:false,
        dialogCloseCash:false,
        extract: [],
        showModalWithdrawal: false,
        showModalDeposit: false,
        search: '',
        icons: {
            mdiMagnify,
            mdiPlus
        },
        // cash: [
        //   {id:1}
        // ],
    }),

    created() {
        this.getExtract().then((response) => {
            this.extract = response.data
        })
    },
    watch: {

    },
    computed: {

    },

    methods: {
        ...mapActions('cash', ['closeCash', 'getExtract']),
        ...mapMutations('cash', ['setCash']),

        showWithdrawal() {
            this.showModalWithdrawal = true
        },

        showDeposit() {
            this.showModalDeposit = true
        },
        // extract() {
        //   let payLoad = {
        //     product_id: this.product_id,
        //     dateExtract: this.dateExtract
        //   }

        //   this.getExtract(payLoad).then(response => {
        //     this.product_extract = response.data
        //   })
        // },
        storeCloseCash() {
          this.dialogCloseCash = true

            // this.$swal({
            //     title: 'Deseja realmente fechar o caixa ?',
            //     text: 'Antes, é importante conferir se os valores estão corretos no Extrato da Gaveta.',
            //     showDenyButton: true,
            //     denyButtonText: 'Não',
            //     confirmButtonText: 'Sim',
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         this.closeCash().then((response) => {
            //                 this.setCash(null)
            //                 this.$router.push('cash_list')
            //                 this.$toast.success('Caixa fechado com sucesso.')
            //             }).catch(() => {})
            //             .finally(() => {})
            //     }
            // })

        },
        confirmCloseCash(){
          this.loadingCloseCash = true
          this.closeCash(this.obsCloseCash).then((response) => {
            this.setCash(null)
              this.$router.push('cash_list')
              this.$toast.success('Caixa fechado com sucesso.')
          }).catch(() => {})
          .finally(() => {
            this.loadingCloseCash = false
          })
        },

        formatMoney(value) {
            value = parseFloat(value)
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        formatDateTime(date) {
            if (date) {
                const result = new Date(date);
                return result.toLocaleDateString('pt-BR', {
                    timeZone: 'America/Sao_Paulo'
                }) + " " + result.toLocaleTimeString('pt-BR')
            }

        },

    },
}
</script>

<style scoped>
.signal_negative {
    color: #ff4c51
}

.signal_positive {
    color: #56ca00

}
</style>
